import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { analyticsStore, parceriaAcaoStore } from '@/utils/store-accessor';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
let Externorroute = class Externorroute extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                show: true,
                table: 'parceria_acao',
                text: 'Data de execução',
                type: '',
                value: 'data',
            },
            {
                show: true,
                table: 'parceria_acao',
                text: 'Horário de inicio',
                type: 'timestamp',
                value: 'horario_inicio',
            },
            {
                show: true,
                table: 'parceria_acao',
                text: 'Horário de fim',
                type: '',
                value: 'horario_fim',
            },
            {
                show: true,
                table: 'parceria',
                text: 'Parceria',
                type: '',
                value: 'nome',
            },
            {
                show: true,
                table: 'parceria',
                text: 'Bairro',
                type: '',
                value: 'bairro',
            },
            {
                show: true,
                table: 'parceria_acao',
                text: 'Ação',
                type: '',
                value: 'acao_id',
            },
            {
                show: true,
                table: 'tmp',
                text: 'Executor',
                type: '',
                value: 'colaboradores',
            },
            {
                show: true,
                table: 'parceria_acao',
                text: 'Descrição',
                type: 'str',
                value: 'descricao',
            },
            {
                show: true,
                table: 'parceria_acao',
                text: 'Status',
                type: '',
                value: 'status_id',
            },
        ];
        this.selectPeriodDate = null;
    }
    get totals() {
        return analyticsStore.totals;
    }
    async buscar() {
        analyticsStore.setDefaultFilters({
            'parceria_acao.data': {
                type: 'timestamp',
                value: [this.selectPeriodDate[0], this.selectPeriodDate[1]],
            },
        });
        await this.doSearchTotals();
        await analyticsStore.executeSearchItems();
    }
    async doSearchItems(queryIn) {
        return await parceriaAcaoStore.getCadernoExternoQuery(queryIn);
    }
    async doSearchTotals() {
        await analyticsStore.getAnalyticsCadernoExterno({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
        });
    }
    async mounted() {
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(parceriaAcaoStore.getCadernoExternoQuery);
        analyticsStore.setHeaders(this.headers);
        // tela que tem defaultFilter nao precisa ter o buscar() aqui
    }
};
Externorroute = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            GenericListTotalCardComponent,
            GenericListItemsCardComponent,
            TextExpandableComponent,
            OpenNewTabComponent,
        },
    })
], Externorroute);
export default Externorroute;
