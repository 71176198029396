var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Caderno de externo")])]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('period-selector-component',{attrs:{"prop-title":"Data da ação"},model:{value:(_vm.selectPeriodDate),callback:function ($$v) {_vm.selectPeriodDate=$$v},expression:"selectPeriodDate"}})],1),_c('v-col',[_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.buscar()}}},[_vm._v(" buscar ")])],1)],1),_c('v-row',[(_vm.totals.por_parceria)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por parceria","prop-items":_vm.totals.por_parceria}})],1):_vm._e(),_c('v-col',[_c('v-row',[(_vm.totals.por_acao)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por ação","prop-items":_vm.totals.por_acao}})],1):_vm._e()],1),_c('v-row',[(_vm.totals.por_status_id)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"prop-title":"Por status","prop-items":_vm.totals.por_status_id}})],1):_vm._e()],1),_c('v-row',[(_vm.totals.por_bairro)?_c('v-col',[_c('GenericListTotalCardComponent',{attrs:{"propTableName":"list_turmas","prop-title":"Por bairro","prop-items":_vm.totals.por_bairro},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var value = ref.value;
return [(!value)?_c('span',[_vm._v("Não informado")]):_c('span',[_vm._v(_vm._s(value))])]}}],null,true)})],1):_vm._e()],1)],1)],1),(_vm.totals.por_parceria)?_c('v-row',[_c('v-col',[_c('GenericListItemsCardComponent',{attrs:{"propTableName":"list_cadernos"},scopedSlots:_vm._u([{key:"item.nome",fn:function(ref){
var item = ref.item;
return [(item.nome)?_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.nome)),"propObjectId":item.parceria_id,"propToolName":"Parcerias"}}):_vm._e()]}},{key:"item.data",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]}},{key:"item.acao_id",fn:function(ref){
var item = ref.item;
return [(item.acao_id)?_c('span',[_vm._v(" "+_vm._s(_vm._.find(_vm.parceriaAcaoOptions(), function (i) { return i.id == item.acao_id; }).name)+" ")]):_vm._e()]}},{key:"item.horario_inicio",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.substring(0, 5))+" ")]}},{key:"item.horario_fim",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value.substring(0, 5))+" ")]}},{key:"item.colaboradores",fn:function(ref){
var value = ref.value;
return _vm._l((value),function(colaborador){return _c('div',[_c('v-chip',{staticClass:"ma-1",attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(colaborador)+" ")])],1)})}},{key:"item.status_id",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"label":"","color":_vm._.find(_vm.tarefaStatusOptions(), function (i) { return i.id == value; }).color}},[_vm._v(" "+_vm._s(_vm._.find(_vm.tarefaStatusOptions(), function (i) { return i.id == value; }).name)+" ")])]}}],null,true)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }